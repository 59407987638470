import React from 'react'
import './App.css'
import Welcome from './components/Welcome'

function App() {
  return (
    <div className="app">
      <Welcome />
    </div>
  )
}

export default App
